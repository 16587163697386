<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>
        <div class="content_section">
            <div class="content">
                <sub-title>이벤트</sub-title>
                <div class="eventlist">
                    <router-link :to="{path:'/event_detail/' + item.id + '?t='+new Date().getTime()}" tag="div" class="eventitem"   :style="{'background':`url('${item.eventImg}')`}"
                                 v-for="item in eventList">

                    </router-link>
                </div>
            </div>
        </div>
        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import {postionMixin} from "../../common/mixin";
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {getEvent} from "../../network/userRequest";
    export default {
        name: "EventList",
        mixins: [postionMixin],

        components: {
            FootComp,
            RightBarBannerComp,
            RightButtonsComp, UserInfoComp, RightBarComp, SubTitle, SportsLeftBarComp, LeftBarComp, TopbarComp
        },
        data() {
            return {
                eventList: [],
                pageNum: 1,
                pageSize: 100,
                total: 1,
                sportsConst,
                clickNumber: 0,
                position: "이벤트",
            }
        },
        methods: {
            initEvent() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getEvent(this.pageNum, this.pageSize).then(res => {
                    if (res.data.success) {
                        this.eventList = res.data.data
                        this.total = res.data.total
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            showContent(id) {
                if (id === this.clickNumber) {
                    this.clickNumber = 0
                } else {
                    this.clickNumber = id;
                }
            },
            pageChange(page) {
                this.pageNum = page
                this.initEvent()
            },
        },
        created() {
            this.initEvent()
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/notice.css");
    .eventlist{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-left: 11px;
    }
    .eventlist .eventitem{
        width: 24.5%;
        height: 260px;
        overflow-y: hidden;
        margin: 5px 1px;
        border: 1px solid #1f1f24;
        background-size: cover!important;
        background-position: top!important;
        position: relative;
    }
    .eventlist .eventitem img{
        width: 100%;
    }
    @media screen and (max-width: 1024px) {
        .eventlist{
            margin-left: 5px;
        }
        .eventlist .eventitem{
            width: 49% !important;
            height: 220px!important;
            overflow-y: hidden;
            margin: 5px 0px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
    }
</style>